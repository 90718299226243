















































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import IconCustomerBlocked from '@/app/ui/assets/icon_customer_blocked.vue'
import IconCustomerUnblocked from '@/app/ui/assets/icon_customer_unblocked.vue'
import CustomerTextInput from '../CustomerTextInput/index.vue'

@Component({
  components: {
    Modal,
    Button,
    CustomerTextInput,
    IconCustomerBlocked,
    IconCustomerUnblocked,
  },
})
export default class CustomerModalBlock extends Vue {
  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: false }) private isDisabled!: boolean
  @Prop({ default: "Title" }) private title!: string
  @Prop({ default: "Description" }) private description!: string
  @Prop({ default: "" }) private type!: 'blocked' | 'unblocked' | 'suspended'

  reason = ''

  get getTextButton(): string {
    if (this.type === 'blocked') {
      return 'Ban'
    } else if (this.type === 'unblocked') {
      return 'Reactivate'
    } else if (this.type === 'suspended') {
      return 'Suspend'
    } else {
      return 'Oke'
    }
  }

  get setIsDisabled(): boolean {
    return this.isDisabled || this.reason.length === 0 || this.reason.length > 255
  }

  private onCancel() {
    this.reason = ''
    this.$emit('cancel')
  }
}
