








































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import CustomerTextInput from '../components/CustomerTextInput/index.vue'
import CustomerModalBlock from '../components/CustomerModal/CustomerModalBlock.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import IconChatBallon from '@/app/ui/assets/icon_chat_ballon.vue'
import controller from '@/app/ui/controllers/ManageCustomerController'
import { ManageCustomer } from '@/domain/entities/ManageCustomer'
import { EventBusConstants } from '@/app/infrastructures/misc'
import { Utils } from '@/app/infrastructures/misc'
import { EnumChangeStatusCustomer } from '@/app/infrastructures/misc/Constants/manageCustomer'

@Component({
  components: {
    CustomerTextInput,
    CustomerModalBlock,
    DropdownSelect,
    Button,
    LoadingOverlay,
    IconChatBallon,
  },
})
export default class ManageCustomerDetail extends Vue {
  controller = controller
  modalBlockCustomer = false
  modalUnblockCustomer = false
  modalSuspendCustomer = false
  form = {
    customerId: 0,
    fullName: '',
    phoneNumber: '',
    email: '',
    gender: '',
    birthdate: '',
    address: '',
    city: '',
    membership: '',
    totalPoints: 0,
    os: '',
    status: '',
    createdAt: '',
    deletedAt: ''
  }
  reason = ''
  Utils = Utils
  EnumChangeStatusCustomer = EnumChangeStatusCustomer

  created(): void {
    controller.getHistoryStatusCustomer(this.$route.params.id)
    controller.getDetailCustomer(this.$route.params.id)
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  private dateHistory(date: string): string {
    return Utils.formatDate(date, 'DD MMMM YYYY • HH:mm')
  }

  private getStatusCustomer = (status: string): string => {
    if (status.toLowerCase() === 'banned') {
      return 'Blocked'
    } else if (status.toLowerCase() === 'nonlogin') {
      return 'Non Login'
    } else {
      return Utils.toCapitalize(status)
    }
  }

  private onCancelModal(type: 'block' | 'unblock' | 'suspend'): void {
    this.reason = ''
    if (type === 'block') {
      this.modalBlockCustomer = false
    } else if (type === 'unblock') {
      this.modalUnblockCustomer = false
    } else if (type === 'suspend') {
      this.modalSuspendCustomer = false
    }
  }

  private onChangeStatusCustomer(status: EnumChangeStatusCustomer): void {
    controller.changeStatusCustomer({
      customerStatus: status,
      customerId: Number(this.$route.params.id),
      reason: this.reason,
    })
  }

  @Watch('controller.statusChangedCustomerStatus')
  onStatusChangedCustomerStatusChanged(status: string): void {
    let title = ''
    switch (status) {
      case EventBusConstants.SUSPEND_CUSTOMER_SUCCESS:
        this.modalSuspendCustomer = false
        title = 'Suspend'
        break;
      case EventBusConstants.BLOCK_CUSTOMER_SUCCESS:
        this.modalBlockCustomer = false
        title = 'Block'
        break;
      case EventBusConstants.UNBLOCK_CUSTOMER_SUCCESS:
        this.modalUnblockCustomer = false
        title = 'Unblock'
        break;
      default:
        break;
    }
    this.$router.push(`/manage-customer`)
    this.$notify({
      title: `${title} Customer`,
      text: `${title} Customer Success`,
      type: 'success',
      duration: 5000,
    })
    controller.setStatusChangedCustomerStatus('')
  }

  @Watch('controller.customerDetail')
  onSetCustomerDetail(data: ManageCustomer): void {
    this.form = {
      ...this.form,
      customerId: <number>data.id,
      fullName: <string>data.fullname || '-',
      phoneNumber: <string>data.phoneNumber || '-',
      email: <string>data.email || '-',
      gender: <string>data.gender || '-',
      birthdate: data.birthdate ? <string>Utils.formatDate(data.birthdate, 'DD/MM/YYYY') : '-',
      address: <string>data.address || '-',
      city: <string>data.city || '-',
      membership: <string>data.membershipLevel || '-',
      totalPoints: <number>data.totalPoints,
      os: <string>data.os || '-',
      status: this.getStatusCustomer(<string>data.status) || '-',
      createdAt: data.createdAt ? Utils.formatDate(data.createdAt, 'DD/MM/YYYY') : '-',
      deletedAt: data.deletedAt ? Utils.formatDate(data.deletedAt, 'DD/MM/YYYY') : '-',
    }
  }

  private getStatus(customerStatus: string, isBanned: boolean): string {
    if (customerStatus !== '') {
      return Utils.toCapitalize(customerStatus)
    }
    return isBanned ? 'Blocked' : 'UnBlocked'
  }

  beforeDestroy(): void {
    controller.setStatusChangedCustomerStatus('')
  }
}
