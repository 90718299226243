































import { Component, Prop, Vue } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'

@Component({
  inheritAttrs: false,
  components: { TextInput },
})
export default class CustomerTextInput extends Vue {
  @Prop({ required: false, }) private fieldLabel!: string
  @Prop() private fieldDescription!: string
  @Prop() private fieldSuffix!: string
  @Prop() private errorMessage!: string
  @Prop({ default: false }) private required!: boolean
  @Prop({ default: '' }) private customClass!: string
  @Prop({ default: false }) private disabled!: boolean
  @Prop({ default: '' }) private maxWidth!: string
}
