import { EnumChangeStatusCustomer } from "@/app/infrastructures/misc/Constants/manageCustomer";
import {
  BlockedCustomerRequestInterface,
  ChangeStatusCustomerRequestInterface,
  UnblockedCustomerRequestInterface
} from "../contracts/ManageCustomer";

export class BlockedCustomerRequest implements BlockedCustomerRequestInterface {
  private reason: string

  constructor(reason: string) {
    this.reason = reason
  }

  public toPayload(): string {
    return JSON.stringify({
      reason: this.reason,
    })
  }
}

export class UnblockedCustomerRequest implements UnblockedCustomerRequestInterface {
  private reason: string

  constructor(reason: string) {
    this.reason = reason
  }

  public toPayload(): string {
    return JSON.stringify({
      reason: this.reason,
    })
  }
}

export class ChangeStatusCustomerRequest implements ChangeStatusCustomerRequestInterface {
  private customerStatus?: EnumChangeStatusCustomer
  private customerId?: number
  private reason?: string

  constructor(
    customerStatus?: EnumChangeStatusCustomer,
    customerId?: number,
    reason?: string
  ) {
    this.customerStatus = customerStatus
    this.customerId = customerId
    this.reason = reason
  }

  toPayload(): string {
    return JSON.stringify({
      customer_status: this.customerStatus,
      customer_id: this.customerId,
      reason: this.reason,
    })
  }
}
