import Vue from 'vue'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { container } from 'tsyringe'
import { ManageCustomerPresenter } from '@/app/ui/presenters/ManageCustomerPresenter'
import { EventBusConstants, Utils } from '@/app/infrastructures/misc'
import { HistoryStatusCustomer, ManageCustomer } from '@/domain/entities/ManageCustomer'
import { Pagination } from '@/domain/entities/Pagination'
import {
  BlockedCustomerRequest,
  ChangeStatusCustomerRequest,
  UnblockedCustomerRequest
} from '@/data/payload/api/ManageCustomerRequest'
import { EnumChangeStatusCustomer } from '@/app/infrastructures/misc/Constants/manageCustomer'

interface ManageCustomerState {
  isLoading: boolean
}

export interface ParamsGetAll {
  query: string
  customerStatus: string,
  sortBy: string,
  page: number,
  perPage: number
}

export interface PayloadChangeStatusInterface {
  customerStatus?: EnumChangeStatusCustomer
  customerId?: number
  reason?: string
}

@Module({ namespaced: true, store, name: 'manageCustomer', dynamic: true })
class ManageCustomerController extends VuexModule implements ManageCustomerState {
  private presenter: ManageCustomerPresenter = container.resolve(ManageCustomerPresenter)
  public isLoading = false
  public paginationData = new Pagination()
  public customerList = [new ManageCustomer()]
  public customerDetail = new ManageCustomer()
  public historyStatusCustomer = [new HistoryStatusCustomer()]
  public statusChangedCustomerStatus = ''

  @Action({ rawError: true })
  public getAll(params?: ParamsGetAll): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then((res) => {
        this.setPagination(<Pagination>res.pagination)
        this.setCustomerList(<ManageCustomer[]>res.data)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch All Customer Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getDetailCustomer(id: string): void {
    this.setLoading(true)

    this.presenter
      .get(id)
      .then((res) => {
        this.setCustomerDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Detail Customer Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getHistoryStatusCustomer(customerId: string): void {
    this.setLoading(true)

    this.presenter
      .getHistoryStatus(customerId)
      .then((res) => {
        this.setHistoryStatusCustomer(<HistoryStatusCustomer[]>res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch History Status Customer Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public changeStatusCustomer(payload: PayloadChangeStatusInterface): void {
    const { customerId, customerStatus, reason } = payload

    this.setLoading(true)

    this.presenter
      .changeStatus(
        new ChangeStatusCustomerRequest(
          customerStatus,
          customerId,
          reason,
        )
      )
      .then(() => {
        switch (customerStatus) {
          case EnumChangeStatusCustomer.SUSPENDED:
            this.setStatusChangedCustomerStatus(EventBusConstants.SUSPEND_CUSTOMER_SUCCESS)
            break;
          case EnumChangeStatusCustomer.BANNED:
            this.setStatusChangedCustomerStatus(EventBusConstants.BLOCK_CUSTOMER_SUCCESS)
            break;
          case EnumChangeStatusCustomer.ACTIVE:
            this.setStatusChangedCustomerStatus(EventBusConstants.UNBLOCK_CUSTOMER_SUCCESS)
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        Vue.notify({
          title: `${customerStatus} Customer Failed`,
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public blockCustomer(payload: {
    reason: string,
    customerId: string
  }): void {
    this.setLoading(true)

    this.presenter
      .blocked(
        new BlockedCustomerRequest(payload.reason),
        payload.customerId
      )
      .then(() => {
        this.setStatusChangedCustomerStatus(EventBusConstants.BLOCK_CUSTOMER_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Block Customer Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public unblockCustomer(payload: {
    reason: string,
    customerId: string
  }): void {
    this.setLoading(true)

    this.presenter
      .unblocked(
        new UnblockedCustomerRequest(payload.reason),
        payload.customerId
      )
      .then(() => {
        this.setStatusChangedCustomerStatus(EventBusConstants.UNBLOCK_CUSTOMER_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Unblock Customer Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  public setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  public setPagination(pagination: Pagination): void {
    this.paginationData = pagination
  }

  @Mutation
  public setCustomerList(data: ManageCustomer[]): void {
    this.customerList = data
  }

  @Mutation
  public setCustomerDetail(data: ManageCustomer): void {
    this.customerDetail = data
  }

  @Mutation
  public setHistoryStatusCustomer(data: HistoryStatusCustomer[]): void {
    this.historyStatusCustomer = data
  }

  @Mutation
  public setStatusChangedCustomerStatus(status: string): void {
    this.statusChangedCustomerStatus = status
  }
}

export default getModule(ManageCustomerController)
